<template>
    <div class="container pd100 apply-schedule">
        <van-cell title="课程信息" />
        <div class="cell-sub mb10">
            <div class="cell-sub-p">
                <div class="cell-sub-p-bd">{{ detail.class_name }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">课程名称：</div>
                <div class="cell-sub-p-bd">{{ detail.course_title }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">课程时间：</div>
                <div class="cell-sub-p-bd">{{ detail.class_date }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">授课老师：</div>
                <div class="cell-sub-p-bd">{{ course_teacher.teacher_name }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">授课教室：</div>
                <div class="cell-sub-p-bd">{{ detail.room_name }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">报名人数：</div>
                <div class="cell-sub-p-bd">{{ detail.reserve_count }} / {{detail.table_count}}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">所属校区：</div>
                <div class="cell-sub-p-bd">{{ course_studio.studio_name }}</div>
            </div>
        </div>

        <van-cell-group>
            <van-cell title="上课学员" class="mb10" is-link icon="passed" :value="`${detail.reserve_count}`" @click="memberClick" />
            <van-cell title="当前等位" class="mb10" icon="warning-o" :value="`${detail.course_class_wait_no}`" @click="waitlistClick" is-link />
        </van-cell-group>
        
        <van-cell title="报名人数" />
        <div class="cell-sub mb10 signup">
            <van-stepper v-model="count" @change="handleStepper" class="mr10" :min="1" :max="countMax" />
        </div>

        <div v-for="(it,j) in formList" :key="j">
            <van-cell :title="`报名人信息 (${j+1})`" />
            <div class="bgf mb10 info">
                <div class="form appointment_schedule_from">
                    <i-field
                    v-for="(item,index) in it"
                    :key="index"
                    :index="index"
                    :parentIndex="j"
                    :field="item"
                    :type="item.filedValType"
                    :name="item.filedDataName"
                    :label="item.filedName"
                    :placeholder="item.filedPlaceholder"
                    :val="item.value"
                    :vals="item.values"
                    @change="onFieldChange"
                    />
                </div>
            </div>
        </div>
        
        <div v-if="detail.class_summary" class="schedule-detail-html">
            <van-cell title="上课内容" />
            <div @click="toPreview($event)" class="ql-editor" v-html="html"></div>
        </div>

        <div class="affix-bar">

        <van-button type="info" v-if="detail.course_class_book_id" block @click="toReplace(detail.course_class_book_id)">我要请假</van-button>

        <van-button class="affix-bar-btn" block :loading="btnLoading" v-if="detail.reserve_count>=detail.table_count && detail.can_wait" type="warning" @click="onConfirm">确认等位</van-button>

        <van-button class="affix-bar-btn" block :loading="btnLoading" v-if="detail.reserve_count<detail.table_count" type="primary" @click="onSubmit">确认报名</van-button>

        </div>
        <!-- <copyright /> -->
        <loadings :loading='loading' />

        <van-popup v-model="booklistShow" position="bottom" round :style="{ height: '80%' }">
            <loadings :loading='bookloading' />
            <van-cell v-for="(sub_item,index) in booklist" :key='index' size="small" :label="sub_item.created_at">
                <template #title>
                    <span class="custom-title" >{{index+1}}. {{sub_item.student_name}}</span>
                </template>
            </van-cell>
            <van-empty v-if="booklist.length == 0 && !loading" description="暂无学员" />
        </van-popup>

        <van-popup v-model="waitlistShow" position="bottom" round :style="{ height: '80%' }">
            <loadings :loading='waitloading' />
            <van-cell v-for="(sub_item,index) in waitlist" :key='index' size="small" :label="sub_item.created_at">
                <template #title>
                    <span class="custom-title" >{{index+1}}. {{sub_item.student_name}}</span>
                </template>
            </van-cell>
            <van-empty v-if="waitlist.length == 0 && !loading" description="暂无等位" />
        </van-popup>

    </div>
</template>

<script>
    import IField from '@/components/Field'
    import Copyright from '@/components/Copyright'
    import { Field, Stepper, ImagePreview, Dialog ,Empty} from 'vant'
    import { isEmpty,isPhone } from '@/common/validate'
    import Loadings from '@/components/loading'
    import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'
    export default {
        name:'apply',
        data() {
            return {
                loading:false,
                waitloading:false,
                waitlist:[],
                booklist:[],
                waitlistShow:false,
                btnLoading:false,
                detail: {course_class_wait_no:'-',reserve_count:'-'},
                course_studio: {},
                course_teacher:{},
                count:1,
                countMax:100,
                form:[],
                formList:[],
                html:[],
                richImages:[],
                course_class_id:'',
                bookloading:false,
                booklistShow:false,
                book_id:this.$route.query.book_id
            }
        },
        components: {
            Copyright,
            [Empty.name]: Empty,
            [Stepper.name]: Stepper,
            [Field.name]: Field,
            [Dialog.name]: Dialog,
            // Tabbar:Tabbar,
            loadings:Loadings,
            IField,
        },
        created () {
            this.loading = true
            this.course_class_id = this.$route.query.course_class_id
            this.getDetail()
        },
        methods: {
            toReplace(course_class_book_id){
                // console.log(course_class_book_id);
                this.$router.push({path:'/student/schedule/detail',query:{book_id:course_class_book_id}})
            },
            memberClick(){
                this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'appointment_course_booklist'}).then(res=>{})
                this.bookloading=true
                this.$api.schedule_booklist({ course_class_id:this.detail.course_class_id }).then(res => {
                    this.booklist = res.data
                    this.bookloading = false;
                })
                this.booklistShow = true;
            },
            waitlistClick(){
                this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'appointment_course_waitlist'}).then(res=>{})
                this.waitloading=true
                this.$api.schedule_waitlist({ course_class_id:this.detail.course_class_id }).then(res => {
                    this.waitlist = res.data
                    this.waitloading = false;
                })
                this.waitlistShow = true;
            },
            onSubmit(){
                this.btnLoading = true
                let formArr = []
                for(let i=0; i<this.formList.length; i++){
                    if(this.formValidate(this.formList[i],i)){
                        formArr.push(this.formList[i].map(item => ({ [item.filedDataName]: item.value })))
                    }else{
                        return false
                    }
                }
                let student = []
                formArr.forEach(item=>{
                    student.push({student_name:item[0].student_name,student_cellphone:item[1].student_cellphone})
                    //student.push({student_name:item[0].student_name,student_cellphone:item[1].student_cellphone,book_remark:item[2].book_remark})
                })
                let obj = {
                    course_class_id:this.course_class_id,
                    student,
                }
                if(this.book_id){
                    obj.replace_book_id = this.book_id
                }
                this.$api.custom_huidong_join_courses(obj)
                .then(res=>{
                    this.btnLoading = false
                    this.$router.push({path:'/submitSuccess',query:{btnType:1,book_list:res.data.book_id}})
                })
                .catch(err=>{
                    this.btnLoading = false
                })
            },
            onConfirm(){
                Dialog.confirm({
                    title: '等位预约确认',
                    message: '当前预约为等位预约，开课前3小时，未候补成功将自动取消候补预约。',
                })
                .then(() => {
                    this.onSubmit();
                    // on confirm
                })
                .catch(() => {
                    // on cancel
                });
            },
            formValidate(form,ind) {
                for (let index = 0; index < form.length; index++) {
                    if (form[index].required && isEmpty(form[index].value)) {
                        this.$toast(`请输入报名人(${ind+1})的${form[index].filedName}`)
                        this.btnLoading = false
                        return false
                    }
                    if(form[index].filedDataName == 'student_cellphone' && !isPhone(form[index].value)){
                        this.$toast(`报名人(${ind+1})的手机号填写不正确~`)
                        this.btnLoading = false
                        return false
                    }
                }
                this.btnLoading = false
                return true
            },
            // getprofile(){
            //     this.$api.profile_index().then(res => {
            //         this.formList[0][0]['value'] = res.data.student.student_name
            //         this.formList[0][1]['value'] = res.data.student.student_cellphone
            //         //this.formList[0][2]['value'] = ''
            //         this.loading = false
            //     })
            // },
            getDetail() {
                this.$api.custom_huidong_campus_schedule_detail({course_class_id:this.course_class_id})
                .then(res=>{
                    this.detail = res.data
                    this.course_teacher = res.data.course_teacher
                    this.course_studio = res.data.course_studio
                    this.countMax = res.data.table_count - res.data.reserve_count
                    this.form = [
                        {filedDataName: "student_name",filedName: "学员姓名",filedPlaceholder: "请输入学员姓名",filedRequired: true,filedType: "input",filedValType: "text",required: true,value: ""},
                        {filedDataName: "student_cellphone",filedName: "联系电话",filedPlaceholder: "请输入联系电话",filedRequired: true,filedType: "input",filedValType: "text",required: true,value: ""},
                    ]
                    this.handleChange(this.count,1)
                    this.formList[0][0]['value'] = res.data.course_student.student_name
                    this.formList[0][1]['value'] = res.data.course_student.student_cellphone
                    
                    if(res.data.class_summary){
                        let arr = addRandomAndGetPreviewImageUrlsFromRichText(res.data.class_summary)
                        this.html = arr[0]
                        this.richImages = arr[1]
                    }
                    this.loading = false
                })
            },
            onFieldChange({ index, value, parentIndex }) {
                this.formList[parentIndex][index].value = value
            },
            handleStepper(e){
                this.handleChange(this.count)
            },
            handleChange(e,num){
                let arr = []
                let oldArr = this.formList
                // 双向绑定问题
                for(let i=0; i<e; i++){
                    let formArr = []
                    this.form.forEach(item=>{
                        formArr.push({...item})
                    })
                    arr.push([...formArr])
                }
                this.formList = arr
                if(this.formList.length > oldArr.length){
                    oldArr.forEach((item,index)=>{
                        this.formList[index] = item
                    })
                }else{
                    this.formList.forEach((item,index)=>{
                        this.formList[index] = oldArr[index]
                    })
                }
            },
            toPreview(e){
                if (e.target.tagName == 'IMG') {
                    this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'appointment_course_class_summary'}).then(res=>{})
                    WeixinJSBridge.invoke("imagePreview", {
                        "urls":this.richImages,
                        "current": e.target.src.split('random=')[1] - 1
                    });
                }
            },
            previewRemarkImg(index){
                WeixinJSBridge.invoke("imagePreview", {
                    "urls":this.detail.cancel_image,
                    "current": this.detail.cancel_image[index]
                });
            },
        },
    }
</script>

<style lang="less" scoped>
  .cell-sub{
    background: #fff;
    padding: 10px 15px;
  }

  .cell-sub-p{
    display: flex;
    margin-bottom: 6px;
    &-hd{
        color: #333;
    }
    &-bd{
        color: #666;
    }
  }
</style>
<style lang="less">
.appointment_schedule_from{
    .i-field-label-align{
        width: 75px;
        text-align-last: inherit;
    }
}
.schedule-detail-html{
    margin-top: 10px;
    img{
        max-width: 100%;
    }
}
</style>